<template>
    <base-nav class="navbar-top navbar-dark"
        id="navbar-main"
        :show-toggle-button="false"
        expand>
        <div class="d-none d-md-flex mr-lg-auto ml-lg-5"></div>

        <div class="aling-items-center d-none d-md-flex">
            <base-dropdown class="nav-link pr-0" position="right">
                <div class="media aling-items-center" slot="title">
                    <span class="avatar avatar-sm rounded-circle bg-primary">
                        <i class="fa fa-window-restore"></i>
                    </span>
                </div>

                <template>
                    <a class="dropdown-item logout" @click="logout">
                        <i class="ni ni-user-run"></i>
                        <span>Logout</span>
                    </a>
                </template>
            </base-dropdown>
            <base-dropdown class="nav-link pr-1" position="right">
                <div class="media aling-items-center" slot="title">
                    <span class="avatar avatar-sm rounded-circle bg-primary">
                        <i class="fa fa-bell" aria-hidden="true"></i>
                        <div class="notify-count count1 common-count" v-if="notifications.length > 0">
                            <div class="value" v-text="notifications.length">0</div>
                        </div>
                    </span>
                </div>

                <div class="notification">
                    <div class="header">
                        <div class="container">
                        <span v-text="notifications.length">0</span> videos waiting for review
                        </div>
                    </div>
                    <div class="notifications overflow-auto">
                        <div class="items" v-for="(notification, i) in notifications" :key="i">
                            <div class="list-item noti" @click="showVideo(notification)">
                                <div class="text fl">
                                    <b class="name fl">{{notification.nickName}}</b> uploaded video
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </base-dropdown>
            <b-button class="btn btn-default-button"  @click="connectWallet">
                <span v-if="!isConnected">{{ $t('general.connect') }}</span>
                <span v-if="isConnected">{{ this.currentAccount.slice(0,7) + '............' + this.currentAccount.slice(this.currentAccount.length - 4, this.currentAccount.length)}} </span>
            </b-button>
        </div>
    </base-nav>
</template>

<script>
import detectEthereumProvider from '@metamask/detect-provider';
import Swal from 'sweetalert2';

    export default {
        data() {
            return {
                activeNotifications: false,
                showMenu: false,
                searchQuery: ''
            }
        },
        methods: {
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false);
            },
            toggleMenu() {
                this.showMenu = !this.showMenu;
            },
            logout() {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push({ name: 'login' })
                    })
            },
            getNotification() {
                this.$store.dispatch('getNotifications')
            },
            showVideo (notification) {
                this.$router.push('/dashboard/' + notification.id); 
            },
            async connect() {
                const provider = await detectEthereumProvider()

                if (provider) {
                    // eslint-disable-next-line no-undef
                    ethereum
                    .request({ method: 'eth_requestAccounts' })
                    .then(this.handleAccountsChanged)
                    .catch((err) => {
                        if (err.code === 4001) {
                        // eslint-disable-next-line no-console
                        console.log('Please connect to MetaMask.')
                        } else {
                        // eslint-disable-next-line no-console
                        console.error(err)
                        }
                    })
                } else {
                    Swal.fire({
                    title: 'Error',
                    text: 'Metamask is not installed or not running',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    footer: '<a href="https://metamask.io/download.html" target="_blank">Go to Metamask</a>'
                    })
                    this.$store.dispatch('setIsConnected', false)
                }
            },
            connectWallet() {
                if (this.isConnected) {
                    Swal.fire({
                    title: this.$t('general.account'),
                    html: `<div class='container-fluid'>
                            <div class='row pb-3 pt-2'>
                                <div class='col-12'>
                                Connected with ${this.wallet}
                                </div>
                            </div>
                            <div class='row pb-3 pt-2'>
                                <div class='col-12'>
                                ${
                                    this.currentAccount.slice(0,7) + 
                                    '............' +
                                    this.currentAccount.slice(this.currentAccount.length - 4, this.currentAccount.length)
                                } 
                                </div>
                            </div>
                            <div class='row pb-3 pt-2'>
                                <div class='col-12'>
                                <button type='button' class='btn btn-default-button btn-block btn-change w-100 wallet'>
                                    ${ this.$t('general.logout') }
                                </button>
                                </div>
                            </div>
                            </div>`,
                    showCloseButton: true,
                    showConfirmButton: false,
                    willOpen: () => {
                        const change = document.querySelector('.btn-change')

                        change.addEventListener('click', () => {
                            this.$router.go()
                        });
                    }
                    })
                } else {
                    this.connect()
                }
            },
            handleAccountsChanged (accounts) {
                if (accounts.length === 0) {
                    this.$store.dispatch('setIsConnected', false)
                    // eslint-disable-next-line no-console
                    console.log('Please connect to MetaMask.')
                } else if (accounts[0] !== this.currentAccount) {
                    this.$store.dispatch('setAddress', accounts[0])
                    this.$store.dispatch('setIsConnected', true)
                    this.$store.dispatch('setWallet', 0)
                } else if (accounts[0] === this.currentAccount && !this.isConnected) {
                    this.$store.dispatch('setIsConnected', true)
                    this.$store.dispatch('setWallet', 0)
                }
            }
        },
        computed: {
            notifications() {
                return this.$store.state.stats.notifications
            },
            isConnected () {
                return this.$store.getters.getIsConnected
            },
            wallet () {
                return this.$store.getters.getWallet
            },
            currentAccount () {
                return this.$store.getters.getAddressMetamask
            },
        },
        async mounted () {
            const provider = await detectEthereumProvider();

            if(provider) {
                // eslint-disable-next-line no-undef
                ethereum.on('accountsChanged', this.handleAccountsChanged)
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Metamask is not installed or not running',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    footer: '<a href="https://metamask.io/download.html" target="_blank">Go to Metamask</a>'
                });
                // eslint-disable-next-line no-console
                console.log('Metamask is not installed or not running')
            }

            window.setInterval(() => {
                this.getNotification()
            }, 15000)
        }
    }
</script>
<style>
.notification .header{
    background: #ffffff;
    width: 100%;
    height: 45px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #D5DFE4;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.notification .header .container{
    width: 275px;
    margin: 0px auto;
    padding-top: 12px;
}

.notifications {
    max-height: 200px;
}

.notification .items{
    background: #E9EFF2;
    width: 100%;
    max-height: 256px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.notification .items .list-item{
    padding: 14px;
    font-size: 13px;
    line-height: 18px;
    border-bottom: 1px solid #D5DFE4;
    height: 35px;
    cursor: pointer;
}

.notification .items .list-item:hover{
    background: #DDECF3;
}

.notification-dropdown .items .list-item:last-child{
    border-bottom: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.notification .items .list-item .text{
    color: #a0b6c3;
    position: relative;
    top: -1px;
    text-align: center;
}

.notification .items .list-item .text b{
    margin-right: 4px;
    color: #6F7A87;
}

.common-count{
  background: rgb(218, 3, 3);
  color: #ffffff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  text-align: center;
}

.count1{
    position: absolute;
    top: 7px;
    width: 1.1rem;
    font-size: .6rem;
    height: 14px;
    padding-top: 0px;
    left: 27px;
}

.logout {
    cursor: pointer;
}

.logout:hover {
    background: #fff !important;
}
</style>