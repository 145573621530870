<template>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
        <side-bar
            :background-color="sidebarBackground"
            short-title="BRD"
            title="BRD">
            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'Dashboard',
                        icon: 'ni ni-tv-2 text-primary',
                        path: '/dashboard'
                        }"
                />
            </template>

            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'User autoapprove',
                        icon: 'ni ni-single-02 text-primary',
                        path: '/userAutoapprove'
                        }"
                />
            </template>

            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'Rewards',
                        icon: 'ni ni-trophy text-primary',
                        path: '/rewards'
                        }"
                />

            </template>

            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'Survey',
                        icon: 'fas fa-poll-h text-primary',
                        path: '/surveyManagement'
                        }"
                />
            </template>

            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'Habits',
                        icon: 'fas fa-apple-alt text-primary',
                        path: '/habits'
                        }"
                />
            </template>

            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'Transaction Scan',
                        icon: 'fas fa-cubes text-primary',
                        path: '/transactionScan'
                    }"
                />
            </template>

            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'Liquidity',
                        icon: 'fas fa-handshake text-primary',
                        path: '/liquidity'
                    }"
                />
            </template>

            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'BPM',
                        icon: 'fas fa-heart text-primary',
                        path: '/bpm'
                    }"
                />
            </template>
        </side-bar>
        <div class="main-content" :data="sidebarBackground">
            <dashboard-navbar></dashboard-navbar>

            <div @click="toggleSidebar">
                <fade-transition :duration="200" origin="center top" mode="out-in">
                    <router-view />
                </fade-transition>
                <content-footer v-if="!$route.meta.hideFooter"></content-footer>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import SidebarItem from '../components/SidebarPlugin/SidebarItem.vue';

    export default {
        components: {
            DashboardNavbar,
            ContentFooter,
            FadeTransition,
                SidebarItem
        },
        data() {
            return {
                sidebarBackground: 'vue'
            };
        },
        methods: {
            toggleSidebar() {
                if (this.$sidebar.showSidebar) {
                    this.$sidebar.displaySidebar(false);
                }
            }
        }
    };
</script>