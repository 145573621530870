import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const locale = 'en-US' //default locale

export default new VueI18n({
    locale,
    messages: {
        'en-US': {
            general: {
                connect: 'CONNECT TO WALLET',
                account: 'Account',
                logout: 'Logout',
                address: 'Address'
            },
            login: {
                header: 'Admin Dashboard',
                singUp: 'Sign up',
                title: 'Login',
                desc: 'Use the 12 words generated when you registered on the platform',
                submit: 'Login'
            },
            register: {
                formErrorHandler: 'Please fill the missing field(s)',
                misspelledError: 'Please ensure all the words are correct',
                unauthorizedError: 'Sorry, your account is not authorized'
            },
            imageList: {
                title: 'Image List',
                totalImages: ' images uploaded',
                communityImages: 'Community images',
                table: {
                    imageName: 'Image name',
                    userName: 'Username',
                    uploadedDate: 'Uploaded date',
                    country: 'Country',
                    status: 'Status'
                }
            },
            videoList: {
                title: 'Video List',
                totalVideos: ' videos uploaded',
                communityVideo: 'Community videos',
                defaultVideo: 'Default per lang videos',
                uploadDefaultVideo: 'Upload default video',
                filter: 'Filters',
                filters: {
                    text: 'Text',
                    filterByStatus: 'Filter by Status',
                    search: 'Search'
                },
                table: {
                    videoName: 'Video name',
                    userName: 'Username',
                    uploadedDate: 'Uploaded date',
                    lang: 'Language',
                    status: 'Status'
                },
                videoModal: {
                    uploaded: 'Uploaded: ',
                    status: 'Status: ',
                    reward: 'AHT reward (up to 1 AHT)',
                    autoapprove: 'Autoapprove all videos for this user in the future',
                    approve: 'Approve',
                    rejectionNote: 'Rejection Notes',
                    reject: 'Reject'
                },
                defaultVideoModal: {
                    title: 'Default video for ',
                    uploaded: 'Uploaded: ',
                    save: 'Save',
                    remove: 'Remove'
                }
            },
            habitList: {
                title: 'Habits List',
                filter: 'Filter',
                totalHabits: ' habits created',
                habits: 'Habit List',
                filters: {
                    text: 'Text',
                    filterByStatus: 'Filter by Status',
                    search: 'Search'
                },
                table: {
                    request: '# of Request',
                    name: 'Habit Name',
                    date: 'Created',
                    actions: 'Actions'
                },
                actions: {
                    addMaster: 'Add to Master',
                    deny: 'Deny',
                    remove: 'Remove',
                    denied: 'Denied',
                    changeStatus: 'Change Status',
                    editHabit: 'Edit Habit',
                },
                edit: {
                    name: 'Name',
                    icon: 'Icon URL',
                    status: 'Status',
                    save: 'Save',
                    cancel: 'Cancel'
                }
            },
            autoApproveUserList: {
                title: 'Auto approve users list',
                filter: 'Filters',
                filters: {
                    text: 'Username',
                    search: 'Search'
                },
                table: {
                    userName: 'Username',
                    address: 'Address',
                    actions: 'Actions'
                }
            },
            userRewards: {
                title: 'User rewards',
                userRewarded: 'Rewarded Users',
                userPendingReward: 'Users Pending Reward',
                totalPendingReward: 'Total Of Pending Reward: {amount} AHT',
                amountAwarded: 'Amount Awarded: {amount} AHT',
                payReward: 'Pay Reward',
                contractBalance: 'Contract Balance: {amount} AHT',
                amountReward: 'Amount Available To Pay Rewards: {amount} AHT',
                table: {
                    pay: 'Pay',
                    address: 'Address',
                    reward: 'AHT Reward',
                    rewarded: 'Rewarded',
                    stakingStatus: 'Staking Status',
                    active: 'Active',
                    inactive: 'Inactive',
                    paid: 'Paid',
                    pending: 'Pending'
                }
            },
            surveyManagement: {
                title: 'Survey Management',
                surveys: 'Surveys',
                createNewSurvey: 'Create New Survey',
                surveysTable: {
                    title: 'Title',
                    rewardPool: 'Reward Pool',
                    creationDate: 'Creation Date',
                    endDate: 'End Date',
                    options: 'Options',
                    actions: 'Actions'
                },
                newSurvey: {
                    name: 'Survey name',
                    options: 'Survey options',
                    endDate: 'End date',
                    rewardPool: 'Reward pool',
                    setAsActive: 'Set as active'
                }
            },
            transactionScan: {
                title: 'Transaction Scan',
                filter: 'Filters',
                placeholderSearch: 'Search by Address / Txn Hash',
                search: 'Search',
                transactionInfo: 'Transaction Details',
                addressInfo: 'Address {address}',
                address: {
                    totalTransactions: 'Total of Transactions:',
                    enrolled: 'Enrolled:'
                },
                transaction: {
                    transactionHash: 'Transaction Hash:',
                    status: 'Status:',
                    block: 'Block:',
                    from: 'From:',
                    to: 'To:',
                    nonce: 'Nonce:',
                    input: 'Input Data:'
                },
                transactionStatus: {
                    pending: 'Pending',
                    failed: 'Failed',
                    success: 'Success'
                },
                error: 'Data input incorrect'
            },
            liquidityManagement: {
                liquidityPool: 'Liquidity pool',
                liquidityEvents: 'Liquidity events',
                pool: {
                    paySelected: 'Pay selected',
                    AHTPending: 'Total AHT pending: {amount}',
                    AHTSend: 'Total AHT to send: {amount}',
                    AHTAvailable: 'AHT available in contract: {amount}',
                    address: 'Address',
                    totalHours: 'Total hours',
                    AHTReward: 'AHT reward',
                    status: 'Status',
                    pending: 'Pending',
                    paid: 'Paid'
                },
                event: {
                    name: 'Name',
                    startDate: 'Start date',
                    endDate: 'End date',
                    reward: 'Reward',
                    status: 'Status',
                    active: 'Active',
                    finished: 'Finished',
                    pending: 'Pending',
                    action: 'Action',
                    start: 'Start',
                    end: 'End',
                    rewardAmount: 'Reward amount',
                    edit: 'Edit event',
                    new: 'New event',
                    saveEvent: 'Save event',
                    cancel: 'Cancel',
                    required: 'Field is required.',
                    minLength: 'Minimum 3 characters required',
                    nameInvalid: 'Event name already exists',
                    rangeInvalid: 'The date range is invalid',
                    amountLimit: 'The maximun amount reward is 1,000,000',
                    error: 'Please ensure all the fields are correct',
                    serverError: 'Something went wrong, Please try again'
                },
                search: 'Search',
                newEvent: '+ Add new event',
                notManager: 'You need to be manager to pay reward',
                deleteConfirmation: 'Are you sure you cant to erase this event?'
            },
            BPMManagement: {
                pendingUsers: 'Pending users',
                paidUsers: 'Paid users',
                search: 'Search',
                reward: 'Reward',
                action: 'Action',
                transaction: 'Transaction',
                country: 'Country',
                date: 'Submission hour'
            }
        }
    }
})