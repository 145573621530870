const Tx = require('ethereumjs-tx').Transaction;
const Common = require('ethereumjs-common').default;
const web3Factory = require('./web3Helper');
const adminPanelAbi = require('../lib/contracts/AdminPanel.json').abi;
const surveyAbi = require('../lib/contracts/Survey.json').abi;
const eConsentAbi = require('../lib/contracts/EConsentApps.json').abi;
const multiTransferAbi = require('../lib/contracts/abiMultiSend.json').abi;
const AHTTokenAbi = require('../lib/contracts/AHTToken.json').abi;
const web3v1Factory = require('./web3v1.3.0Helper');
import LoginService from '@/services/LoginService.js';

/**
 * Sings locally a transaction
 * @param {Object} rawTx The transaction data
 * @param {String} pk The private key to sing the transaction
 * @return {String} The serialized transaction
 */
function singTransaction(rawTx, pk) {
    const customCommon = Common.forCustomChain(
        'mainnet',
        {
            'name': 'aht',
            'chainId': Number(rawTx.chainId)
        },
        'petersburg'
    );
    let tx = new Tx(rawTx, { 'common': customCommon });
    tx.sign(Buffer.from(pk, 'hex'));

    return tx.serialize();
}

/**
 * Sends the transaction to the blockchain
 * @param {Object} web3 The web3 instance
 * @param {Object} tx The signed transaction
 * @return {Promise} A promise resolved when the transaction is sent
 */
function sendSignedTransaction(web3, tx){
    return new Promise((resolve, reject) => {
        web3.aht.sendRawTransaction('0x' + tx.toString('hex'), (err, hash) => {
            if (err) {
                reject({
                    'status': 500,
                    'message': 'Blockchain Error: sending transaction',
                    'systemMessage': err
                })
            }

            resolve(hash);
        })
    })
}

/**
 * Returns the user transaction once
 * @param {Object} web3 The web3 instance
 * @param {String} address The address to get the nonce
 * @return {Promise} A resolved promise when the nonce is retrieved
 */
async function getNonce(web3, address) {
    let lastNonce = await LoginService.getNonce(address);
    let actualNonce = lastNonce.data !== "" ? lastNonce.data.nonce : 0;
    
    return new Promise((resolve, reject) => {
        web3.aht.getTransactionCount(address, async (err, nonce) => {
            if (err) {
                reject({
                    'status': 500,
                    'message': 'Blockchain error: calculating the nonce',
                    'systemMessage': err
                });
                return;
            }


            if(actualNonce === undefined || actualNonce === null || actualNonce === 'null' || actualNonce < nonce) {
                actualNonce = nonce;
            } else {
                actualNonce++;
            }

            await LoginService.updateNonce({
                'address': address,
                'nonce': actualNonce,
            });

            resolve(actualNonce);
        })
    })
}

/**
 * Returns the transaction receipt
 * @param {String} txtHash The transaction hash
 * @return {Promise<Object>}    A promise resolved with the tranasction data
 */
function getTransactionReceipt(txHash) {
    let web3 = web3Factory.getInstance()

    return new Promise((resolve, reject) => {
        web3.aht.getTransactionReceipt(txHash, (err, result) => {
            if (err) {
                reject(err);
                return;
            }

            resolve(result);
        });
    });
}

function getTransactionCount (address) {
    let web3 = web3Factory.getInstance()

    return new Promise((resolve, reject) => {
        web3.aht.getTransactionCount(address, (err, result) => {
            if (err) {
                reject(err)
                return
            }

            resolve(result)
        })
    })
}

function getTransactionDetail (txnHash) {
    let web3 = web3Factory.getInstance()

    return new Promise((resolve, reject) => {
        web3.aht.getTransaction(txnHash, (err, result) => {
            if (err) {
                reject(err)
                return
            }

            resolve(result)
        })
    })
}

async function isEnrolled (txData) {
    let web3 = web3Factory.getInstance()

    let contract = web3.aht.contract(eConsentAbi).at(txData.contractAddress)
    
    let fn = contract.isEnrolled.bind(
        contract,
        txData.address,
        web3.fromAscii(txData.agreement),
        { from: txData.address} 
    )

    let result = await promersify(fn);

    return result
}

function promersify (fn) {
    return new Promise((resolve, reject) => {
        fn((err, result) => {
            if (err) {
            reject(err);
            return;
          }
  
          resolve(result);
        });
    });
}

async function approveVideo(txData, intention = 0) {
    let web3 = web3Factory.getInstance(),
    
    contract = web3.aht.contract(adminPanelAbi).at(txData.contractAddress),
    rawTx = {
        'to': txData.contractAddress,
        'nonce': web3.toHex(await getNonce(web3, txData.address)),
        'gasPrice': Number(process.env.VUE_APP_GAS_PRICE),
        'gasLimit': 50000000,
        'value': '0x0',
        'data': contract.approveCommunityContent.getData(
            txData.userAddress,
            web3.fromAscii('video'),
            web3.fromAscii(txData.videoId),
            web3.toCell(Number(txData.aht))
        ),
        'chainId': txData.chainId
    };
    
    try {
        let signedTx = singTransaction(rawTx, txData.privateKey),
            results = await sendSignedTransaction(web3, signedTx);

        return results;
    } catch (error) {
        let message = error.systemMessage.message
        if (message === 'replacement transaction underpriced' &&  intention < 3) {
            return await approveVideo(txData, intention + 1);
        } else {
            throw 'Could not store the data into the smart contract';  
        }
    }
}

async function rejectVideo(txData, intention = 0) {
    let web3 = web3Factory.getInstance(),
        contract = web3.aht.contract(adminPanelAbi).at(txData.contractAddress),
        rawTx = {
                'to': txData.contractAddress,
                'nonce': web3.toHex(await getNonce(web3, txData.address)),
                'gasPrice': Number(process.env.VUE_APP_GAS_PRICE),
                'gasLimit': 50000000,
                'value': '0x0',
                'data': contract.disableContent.getData(
                    txData.userAddress,
                    web3.fromAscii('video'),
                    web3.fromAscii(txData.videoId),
                    txData.rejectionNote
                ),
                'chainId': txData.chainId
            };

    try {
        let signedTx = singTransaction(rawTx, txData.privateKey),
            results = await sendSignedTransaction(web3, signedTx);
        
        return results;
    } catch (error) {
        let message = error.systemMessage.message
        if (message === 'replacement transaction underpriced' &&  intention < 3) {
            return await approveVideo(txData, intention + 1);
        } else {
            throw 'Could not store the data into the smart contract';  
        }
    }
}

async function getSurveyActive() {
    let web3 = web3v1Factory.getInstance();
    let contractAddress = process.env.VUE_APP_SURVEY_ADDRESS
    
    let contract = new web3.eth.Contract(surveyAbi, contractAddress)

    return new Promise((resolve, reject) => {
        contract.methods.getSurveyActive().call(function (error, result) {
            if(!error)
                resolve({result})
            else
                reject(error.code)  
        })
    })
}

async function getRewardInfo(txData) {
    let web3 = web3v1Factory.getInstance()
    let today = new Date();
    let dateConsult = parseInt((today.getTime() / 1000).toFixed(0))

    let contract = new web3.eth.Contract(surveyAbi, txData.contractAddress, { from: txData.address })

    return new Promise((resolve, reject) => {
        contract.methods.getRewardInfo(txData.surveyName, dateConsult).call(function (error, result) {
            if (error) {
                reject({
                    'status': 500,
                    'systemMessage': error
                });
                return;
            }
            resolve(result)
        })
    })
}

async function sendReward (txData) {
    let web3 = web3v1Factory.getInstance()

    let today = new Date();
    let datePayout = parseInt((today.getTime() / 1000).toFixed(0))

    web3.eth.getTransactionReceiptMined = require('./getTransactionReceiptMined.js')

    let contract = new web3.eth.Contract(surveyAbi, txData.contractAddress, { from: txData.address })

    let transactionParameters = {
        to: txData.contractAddress,
        from: txData.address,
        data: contract.methods.sendMultipleReward(
            txData.surveyName,
            txData.participantsAddress,
            txData.rewards,
            datePayout
        ).encodeABI()
    }

    // eslint-disable-next-line no-undef
    const txHash = await ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
    });

    await web3.eth.getTransactionReceiptMined(txHash)
}

async function getSurveyContractBalance(txData) {
    let web3 = web3v1Factory.getInstance()

    let contract = new web3.eth.Contract(surveyAbi, txData.contractAddress)
    
    return new Promise((resolve, reject) => {
        contract.methods.getContractBalance().call(function (error, result) {
            if (error) {
                reject({
                    'status': 500,
                    'systemMessage': error
                });
                return;
            }
            resolve(result)
        })
    })
}

async function getSurveyList(txData) {
    let web3 = web3v1Factory.getInstance()

    let contract = new web3.eth.Contract(surveyAbi, txData.contractAddress)

    return new Promise((resolve, reject) => {
        contract.methods.getSurveyList().call(function (error, result) {
            if (error) {
                reject({
                    'status': 500,
                    'systemMessage': error
                })
                return
            }
            resolve(result)
        })
    })
}

async function getSurvey(txData) {
    let web3 = web3v1Factory.getInstance()
    
    let contract = new web3.eth.Contract(surveyAbi, txData.contractAddress)

    return new Promise((resolve, reject) => {
        contract.methods.getSurvey(txData.surveyName).call(function (error, result) {
            if (error) {
                reject({
                    'status': 500,
                    'systemMessage': error
                })
                return
            }
            resolve(result)
        })
    })
}

async function saveSurvey (txData) {
    let web3 = web3v1Factory.getInstance()
    const surveyAddress = process.env.VUE_APP_SURVEY_ADDRESS

    web3.eth.getTransactionReceiptMined = require('./getTransactionReceiptMined.js')

    let contract = new web3.eth.Contract(surveyAbi, surveyAddress, { from: txData.address })
    
    const surveyName = web3.utils.asciiToHex(txData.surveyName)
    let options = []
    const endDate = parseInt((txData.endDate.getTime() / 1000).toFixed(0))

    const tokenDecimals = web3.utils.toBN(18);
    const tokenAmount = web3.utils.toBN(txData.rewardPool)
    const rewardPool = web3.utils.toHex(tokenAmount.mul(web3.utils.toBN(10).pow(tokenDecimals)));

    for (let i = 0; i < txData.surveyOptions.length ; i++) {
        options.push(web3.utils.asciiToHex(txData.surveyOptions[i]))
    }

    let transactionParameters = {
        to: surveyAddress,
        from: txData.address,
        data: contract.methods.addSurvey(
            surveyName,
            options,
            endDate,
            rewardPool
        ).encodeABI()
    }

    // eslint-disable-next-line no-undef
    const txHash = await ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
    });

    await web3.eth.getTransactionReceiptMined(txHash)
}

async function setSurveyAsActive (txData) {
    let web3 = web3v1Factory.getInstance()

    web3.eth.getTransactionReceiptMined = require('./getTransactionReceiptMined.js')

    let contract = new web3.eth.Contract(surveyAbi, txData.contractAddress, { from: txData.address })

    let transactionParameters = {
        to: txData.contractAddress,
        from: txData.address,
        data: contract.methods.setSurveyAsActive(
            txData.surveyName
        ).encodeABI()
    }

    // eslint-disable-next-line no-undef
    const txHash = await ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
    });

    await web3.eth.getTransactionReceiptMined(txHash)
}

async function getAmountStacked(txData) {
    let web3 = web3v1Factory.getInstance()

    let contract = new web3.eth.Contract(surveyAbi, txData.contractAddress)

    return new Promise((resolve, reject) => {
        contract.methods.getTotalAmountStacked().call(function (error, result) {
            if (error) {
                reject({
                    'status': 500,
                    'systemMessage': error
                });
                return;
            }
            resolve(result)
        })
    })
}

async function getContractBalance () {
    let web3 = web3v1Factory.getInstance()
    const AHTTokenAddress = process.env.VUE_APP_ERC20_ADDRESS
    const MultiTransferAddress = process.env.VUE_APP_MULTI_TRANSFER_ADDRESS

    let contract = new web3.eth.Contract(AHTTokenAbi, AHTTokenAddress)

    return new Promise((resolve, reject) => {
        contract.methods.balanceOf(MultiTransferAddress).call(function (error, result) {
            if (error) {
                reject({
                    'status': 500,
                    'systemMessage': error
                })
                return
            }
            resolve(result)
        })
    })
}

async function isManager(address) {
    let web3 = web3v1Factory.getInstance()
    const MultiTransferAddress = process.env.VUE_APP_MULTI_TRANSFER_ADDRESS

    let contract = new web3.eth.Contract(multiTransferAbi, MultiTransferAddress)

    return new Promise((resolve, reject) => {
        contract.methods.isManager(address).call(function (error, result) {
            if (error) {
                reject({
                    'status': 500,
                    'systemMessage': error
                })
                return
            }

            resolve(result)
        })
    })

}

async function multiTransferReward(txData) {
    let web3 = web3v1Factory.getInstance()

    web3.eth.getTransactionReceiptMined = require('./getTransactionReceiptMined.js')

    const multiTransferAddress = process.env.VUE_APP_MULTI_TRANSFER_ADDRESS

    let contract = new web3.eth.Contract(multiTransferAbi, multiTransferAddress, { from: txData.address })

    let transactionParameters = {
        to: multiTransferAddress,
        from: txData.address,
        data: contract.methods.multiTransfer(
            txData.addresses,
            txData.tokens
        ).encodeABI()
    }

    const txHash = await ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
    })

    await web3.eth.getTransactionReceiptMined(txHash)
}

async function payReward (txData) {
    const web3 = web3v1Factory.getInstance();

    const amount = web3.utils.toWei(txData.AHT.toString());

    const contract = new web3.eth.Contract(AHTTokenAbi, txData.contractAddress, { from: txData.address});

    let transactionParameters = {
        to: txData.contractAddress,
        from: txData.address,
        gasPrice: web3.utils.toWei('1', 'gwei'),
        gas: web3.utils.toHex(2000000),
        data: contract.methods.transfer(
            txData.userAddress,
            amount
        ).encodeABI()
    }

    // eslint-disable-next-line no-undef
    const txHash = await ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
    });

    return txHash;
}

async function isBPMManager(address) {
    let web3 = web3v1Factory.getInstance()
    const MultiTransferAddress = process.env.VUE_APP_MULTI_TRANSFER_BPM_ADDRESS

    let contract = new web3.eth.Contract(multiTransferAbi, MultiTransferAddress)

    return new Promise((resolve, reject) => {
        contract.methods.isManager(address).call(function (error, result) {
            if (error) {
                console.log(error);
                reject({
                    'status': 500,
                    'systemMessage': error
                })
                return
            }

            resolve(result)
        })
    })

}

async function payMultiReward (txData) {
    const web3 = web3v1Factory.getInstance();

    const contract = new web3.eth.Contract(multiTransferAbi, txData.contractAddress, { from: txData.address});

    let transactionParameters = {
        to: txData.contractAddress,
        from: txData.address,
        gasPrice: web3.utils.toWei('1', 'gwei'),
        gas: web3.utils.toHex(2000000),
        data: contract.methods.multiTransfer(
            txData.addresses,
            txData.tokens
        ).encodeABI()
    }

    // eslint-disable-next-line no-undef
    const txHash = await ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
    });

    return txHash;
}

export {
    approveVideo,
    rejectVideo,
    getTransactionReceipt,
    getRewardInfo,
    sendReward,
    getSurveyActive,
    getSurveyContractBalance,
    getSurveyList,
    getSurvey,
    saveSurvey,
    setSurveyAsActive,
    getTransactionCount,
    getTransactionDetail,
    isEnrolled,
    getAmountStacked,
    getContractBalance,
    isManager,
    multiTransferReward,
    payReward,
    payMultiReward,
    isBPMManager,
}
