<template>
    <div class="main-content bg-auth">

        <!-- Header -->
        <div class="header pb-5 pt-lg-8">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-6">
                            <img src="img/icons/auth_logo.svg" alt="logo" width="120">
                            <h1 class="text-primary pt-2" ref="mainheader">{{ $t('login.header') }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <slide-y-up-transition mode="out-in" origin="center top">
                <router-view v-on:sectionMounted="changeTitle"></router-view>
            </slide-y-up-transition> 
        </div>
    </div>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions'

    export default {
        name: 'auth-layout',
        components: {
            SlideYUpTransition
        },
        data() {
            return {
                year: new Date().getFullYear(),
                showMenu: false
            }
        },
        methods: {
            changeTitle(title) {
                this.$refs.mainheader.innerText = title;
            }
        },
    }
</script>