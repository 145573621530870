var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"BRD","title":"BRD"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: 'Dashboard',
                    icon: 'ni ni-tv-2 text-primary',
                    path: '/dashboard'
                    }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: 'User autoapprove',
                    icon: 'ni ni-single-02 text-primary',
                    path: '/userAutoapprove'
                    }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: 'Rewards',
                    icon: 'ni ni-trophy text-primary',
                    path: '/rewards'
                    }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: 'Survey',
                    icon: 'fas fa-poll-h text-primary',
                    path: '/surveyManagement'
                    }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: 'Habits',
                    icon: 'fas fa-apple-alt text-primary',
                    path: '/habits'
                    }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: 'Transaction Scan',
                    icon: 'fas fa-cubes text-primary',
                    path: '/transactionScan'
                }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: 'Liquidity',
                    icon: 'fas fa-handshake text-primary',
                    path: '/liquidity'
                }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                    name: 'BPM',
                    icon: 'fas fa-heart text-primary',
                    path: '/bpm'
                }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }