import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'login',
      component: DashboardLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/dashboard',
          name: 'General Admin Dashboard',
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        {
          path: '/dashboard/:id',
          name: 'General Admin Dashboard',
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        {
          path: '/userAutoapprove',
          name: 'User Autoapprove',
          component: () => import('./views/UserAutoapprove.vue')
        },
        {
          path: '/rewards',
          name: 'Survey Rewards',
          component: () => import(/* webpackChunkName: "demo" */ './views/SurveyRewards.vue')
        },
        {
          path: '/surveyManagement',
          name: 'Survey Management',
          component: () => import(/* webpackChunkName: "demo" */ './views/SurveyManagement.vue')
        },
        {
          path: '/transactionScan',
          name: 'Transaction Scan',
          component: () => import(/* webpackChunkName: "demo" */ './views/TransactionScan.vue')
        },
        {
          path: '/habits',
          name: 'Habits Dashboard',
          component: () => import('./views/Habits.vue')
        },
        {
          path: '/liquidity',
          name: 'Liquidity',
          component: () => import('./views/Liquidity.vue')
        },
        {
          path: '/bpm',
          name: 'BPM',
          component: () => import(/* webpackChunkName: "demo" */ './views/BPM.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const loggedIn = store.getters.getHash

  if (requiresAuth && !loggedIn) next('login')
  else if (!requiresAuth && loggedIn) next('dashboard')
  else next()
})

export default router
