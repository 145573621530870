import LoginService from '@/services/LoginService.js'
import moment from 'moment'
import langs from './langs.js'
const ahtTransactions = require('../../lib/ahtTransactions.js');
const web3v1Factory = require('../../lib/web3v1.3.0Helper');

export const state = {
    images: {},
    totalImages: 0,
    totalImagesFiltered: 0,
    videos: {},
    totalVideos: 0,
    totalVideosFiltered: 0,
    notifications: {},
    users: [],
    rewardInfo: [],
    surveyName: '',
    contractBalance: 0,
    surveyList: [],
    transactionCount: 0,
    transactionDetails: null,
    enrolled: false,
    amountStacked: 0,
    liquidityEvents: [],
    liquidityPool: [],
    validDate: false,
    multiTransferBalance: 0,
    manager: false,
    validName: false,
    bpmPendingUsers: [],
    bpmPaidUsers: [],
    bpmCountPendingUsers: 0,
    bpmCountPaidUsers: 0
}

/**
 * Get video list
 * @param {array} data The data returned by the server
 * @return {Object} An object with the videos
 */
function getVideoList(data) {
    let results = []
    let status = ['Waiting for review', 'Active', 'Rejected']

    if (data) {
        data.forEach(video => {
            results.push({
                'videoId': video._id,
                'videoName': video.key,
                'createdAt': moment(video.uploadDate).format('MMMM DD, YYYY hh:mm A'),
                'uploaded': moment(video.uploadDate).format('DD-MM-YYYY'),
                'language': langs[video.lang].name,
                'status': video.status,
                'statusDescription': status[video.status],
                'userAddress': video.address,
                'userNickname': video.user.userNickname ? video.user.userNickname : 'Anonymous',
                'videoUrl': video.videoUrl,
                'aht': video.rewardAmount ? video.rewardAmount : 15,
                'autoApprove': video.user.autoApprove,
                'rejectionNote': video.rejectionNote,
                'rewardTx': video.rewardTx ? video.rewardTx : '',
            })
        })
    }

    return results
}

/**
 * Get count videos
 * @param {array} data The data returned by the server
 * @return {Object} An object with the videos
 */
function getCountVideos(data) {
    return data.totalVideos;
}

/**
 * Get pagination number of video list
 * @param {array} data The data returned by the server
 * @return {Object} An object with the videos
 */
function getPaginationNumber(data) {
    return data.totalVideosFiltered;
}

/**
 * Get default video list
 * @param {array} data The data returned by the server
 * @return {Object} An object with the videos
 */
function getDefaultVideoList(data) {
    let results = []

    if (data) {
        data.forEach(video => {
            results.push({
                'videoId': video._id,
                'videoName': video.key,
                'createdAt': moment(video.uploadDate).format('MMMM DD, YYYY hh:mm A'),
                'uploaded': moment(video.uploadDate).format('DD-MM-YYYY'),
                'language': langs[video.lang].name,
                'lang': video.lang.toUpperCase(),
                'videoUrl': video.videoUrl
            })
        })
    }

    return results
}

function getNotifications(data) {
    let notifications = [];

    data.forEach(notification => {
        notifications.push({
            'id': notification._id,
            'nickName': notification.user.userNickname,
            'uploaded': moment(Notification.uploadDate).format('DD-MM-YYYY'),
        })
    })

    return notifications;
}

function getRewardInfo(data) {
    let rewardInfo = []
    let web3 = web3v1Factory.getInstance()

    for(let i = 0; i < data[0].length; i++){
        rewardInfo.push({
            address: data[0][i],
            stakingTime: ((Number(data[1][i]) / 60) / 60),
            reward: web3.utils.fromWei(data[2][i]),
            originalReward: data[2][i],
            rewarded: data[3][i],
            stakeStatus: data[4][i],
            pay: false
        })
    }

    return rewardInfo
}

/**
 * Get auto approve users list
 * @param {array} data The data returned by the server
 * @return {Object} An object with the users
 */
function getAutoapproveUsers(data) {
    let users = []

    if (data) {
        data.forEach(user => {
            users.push({
                'username': user.nickname,
                'address': user.address,
                'id': user._id
            })
        })
    }

    return users
}

/**
 * Get image list
 * @param {array} data The data returned by the server
 * @return {Object} An object with the images
 */
 function getImageList(data) {
    let results = []
    let status = ['Waiting for review', 'Active', 'Rejected']

    if (data) {
        data.forEach(image => {
            results.push({
                'imageId': image._id,
                'imageName': image.key,
                'createdAt': moment(image.uploadDate).format('MMMM DD, YYYY hh:mm A'),
                'uploaded': moment(image.uploadDate).format('DD-MM-YYYY'),
                'country': image.countryCode,
                'status': image.status,
                'statusDescription': status[image.status],
                'userAddress': image.address,
                'userNickname': image.user.userNickname ? image.user.userNickname : 'Anonymous',
                'imageUrl': image.imageUrl,
                'aht': image.rewardAmount ? image.rewardAmount : 10,
                'autoApprove': image.user.autoApprove,
                'rejectionNote': image.rejectionNote,
                'rewardTx': image.rewardTx ? image.rewardTx : '',
            })
        })
    }

    return results
}

/**
 * Get count images
 * @param {array} data The data returned by the server
 * @return {Object} An object with the images
 */
function getCountImages(data) {
    return data.totalImages;
}

function getBPMUsers(data) {
    let results = []
    let status = ['Waiting for review', 'Active', 'Rejected']
    const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

    if (data) {
        data.forEach(video => {
            results.push({
                'videoId': video._id,
                'videoName': video.key,
                'createdAt': moment(video.uploadDate).format('MMMM DD, YYYY hh:mm A'),
                'uploaded': moment(video.uploadDate).utc().format('YYYY-MM-DD HH:mm'),
                'language': langs[video.lang].name,
                'status': video.status,
                'statusDescription': status[video.status],
                'userAddress': video.address,
                'userNickname': 'Anonymous',
                'videoUrl': video.videoUrl,
                'rewardAmount': video.rewardAmount ? video.rewardAmount : 0,
                'rejectionNote': video.rejectionNote ? video.rejectionNote : '',
                'rewardTx': video.rewardTx ? `${video.rewardTx.slice(0,35)}...` : '',
                'country': regionNames.of(video.countryCode),
                'selected': false,
                'paymentAddress': video.profile.paymentEthAddress,
                'mesh': video.mesh ? video.mesh : ''
            })
        })
    }

    return results
}

export const mutations = {
    ADD_IMAGE_DATA(state, data) {
        state.images = getImageList(data)
    },
    ADD_COUNT_IMAGE_DATA(state, data) {
        state.totalImages = getCountImages(data)
        state.totalImagesFiltered = data.totalImagesFiltered
    },
    ADD_VIDEO_DATA(state, data) {
        state.videos = getVideoList(data)
    },
    ADD_COUNT_VIDEO_DATA(state, data) {
        state.totalVideos = getCountVideos(data)
        state.totalVideosFiltered = getPaginationNumber(data)
    },
    ADD_DEFAULT_VIDEO_DATA(state, data) {
        state.videos = getDefaultVideoList(data)
    },
    ADD_COUNT_DEFAULT_VIDEO_DATA(state, data) {
        state.totalVideos = getCountVideos(data)
        state.totalVideosFiltered = getPaginationNumber(data)
    },
    APPROVE_VIDEO(state, data) {
        return data
    },
    REJECT_VIDEO(state, data) {
        return data
    },
    ADD_VIDEO_NOTIFICATIONS(state, data) {
        state.notifications = getNotifications(data);
    },
    ADD_AUTO_APPROVE_USER_DATA(state, data) {
        state.users = getAutoapproveUsers(data)
    },
    async ADD_REWARD_INFO (state, data) {
        state.rewardInfo = await getRewardInfo(data)
    },
    ADD_SURVEY_NAME (state, data) {
        state.surveyName = data.result
    },
    ADD_CONTRACT_BALANCE (state, data) {
        let web3 = web3v1Factory.getInstance()
        state.contractBalance = web3.utils.fromWei(data)
    },
    ADD_CONTRACT_LIST (state, data) {
        state.surveyList = data
    },
    ADD_TRANSACTION_COUNT (state, count) {
        state.transactionCount = count
    },
    ADD_TRANSACTION_DETAILS (state, info) {
        state.transactionDetails = info
    },
    SET_ENROLLED (state, status) {
        state.enrolled = status
    },
    ADD_AMOUNT_STACKED (state, amount) {
        let web3 = web3v1Factory.getInstance()
        state.amountStacked = web3.utils.fromWei(amount)
    },
    ADD_LIQUIDITY_EVENTS_LIST (state, data) {
        state.liquidityEvents = data
    },
    ADD_LIQUIDITY_POOL_LIST (state, data) {
        state.liquidityPool = data
    },
    UPDATE_DATE_STATUS (state, data) {
        state.validDate = data
    },
    ADD_MULTI_TRANSFER_BALANCE (state, data) {
        let web3 = web3v1Factory.getInstance()
        state.multiTransferBalance = web3.utils.fromWei(data)
    },
    ADD_MANAGER_PERMISSION (state, data) {
        state.manager = data
    },
    UPDATE_NAME_STATUS (state, data) {
        state.validName =  data
    },
    ADD_BPM_LIST (state, data) {
        state.bpmPaidUsers = getBPMUsers(data.filter(user => user.status === 1))
        state.bpmPendingUsers = getBPMUsers(data.filter(user => user.status === 0))
    },
    ADD_BPM_COUNT (state, data) {
        state.bpmCountPaidUsers = data.totalPaidUsers;
        state.bpmCountPendingUsers = data.totalPendingUsers;
    }
}

export const actions = {
    getImageList({ commit }, filters = []) {
        return LoginService.getImageList(filters)
            .then(({data}) => {
                commit('ADD_IMAGE_DATA', data)
            })
    },
    getCountImages({ commit }, filters = []) {
        return LoginService.getCountImages(filters)
            .then(({ data }) => {
                commit('ADD_COUNT_IMAGE_DATA', data)
            })
    },
    getVideoList({ commit }, filters = []) {
        return LoginService.getVideoList(filters)
            .then(({data}) => {
                commit('ADD_VIDEO_DATA', data)
            })
    },
    getCountVideos({ commit }, filters = []) {
        return LoginService.getCountVideos(filters)
            .then(({ data }) => {
                commit('ADD_COUNT_VIDEO_DATA', data)
            })
    },
    getDefaultVideoList({ commit }, filters = []) {
        return LoginService.getDefaultVideoList(filters)
            .then(({ data }) => {
                commit('ADD_DEFAULT_VIDEO_DATA', data)
            })
    },
    getCountDefaultVideos({ commit }, filters = []) {
        return LoginService.getCountDefaultVideos(filters)
            .then(({data}) => {
                commit('ADD_COUNT_DEFAULT_VIDEO_DATA', data)
            })
    },
    async approveVideo({ commit }, videoData) {
        const tokenAddress = process.env.VUE_APP_AHT_MATIC_ADDRESS
        let adminPanelAddress = process.env.VUE_APP_ADMIN_PANEL_ADDRESS,
            credentials = JSON.parse(window.localStorage.getItem('credentials')),
            txData;
        
        try {
            txData = await ahtTransactions.approveVideo({
                'contractAddress': adminPanelAddress,
                'address': credentials.address,
                'userAddress': videoData.userAddress,
                'videoId': videoData.videoId,
                'aht': videoData.aht,
                'chainId': Number(process.env.VUE_APP_CHAIN_ID),
                'privateKey': credentials.privateKey
            });
            videoData.lastTxUpdate = txData;

            if(videoData.rewardTx === '') {
                const txData = {
                    'contractAddress': tokenAddress,
                    'address': videoData.address,
                    'userAddress': videoData.userAddress,
                    'AHT': videoData.aht,
                };
            
                const txHash = await ahtTransactions.payReward(txData);

                videoData.rewardTx = txHash;
            }

            return LoginService.approveVideo(videoData)
                .then(({data}) => {
                    commit('APPROVE_VIDEO', data)
                })

        } catch (error) {
            throw error;
        }
    },
    async rejectVideo({ commit }, videoData) {

        let adminPanelAddress = process.env.VUE_APP_ADMIN_PANEL_ADDRESS,
            credentials = JSON.parse(window.localStorage.getItem('credentials')),
            txData;
        
        try {
            txData = await ahtTransactions.rejectVideo({
                'contractAddress': adminPanelAddress,
                'address': credentials.address,
                'userAddress': videoData.userAddress,
                'videoId': videoData.videoId,
                'chainId': Number(process.env.VUE_APP_CHAIN_ID),
                'privateKey': credentials.privateKey,
                'rejectionNote': videoData.rejectionNote
            });

            videoData.lastTxUpdate = txData;

            return LoginService.rejectVideo(videoData)
                .then(({data}) => {
                    commit('REJECT_VIDEO', data)
                })

        } catch (error) {
            throw error;
        }
    },
    async uploadDefaultVideo(videoData) {
        let newDefaultVideo = {
            'name': videoData.name,
            'videoFormat': videoData.videoFormat,
            'lang': videoData.lang,
            'videoCover': videoData.videoCover
        }

        let data = await LoginService.addDefaultVideo(newDefaultVideo);

        return LoginService.uploadDefaultVideo(data.data, videoData.file);
    },
    getNotifications({ commit, getters }) {
        let hash = getters.getHash

        if (hash) {
            return LoginService.getNotifications()
                .then(({data}) => {
                    commit('ADD_VIDEO_NOTIFICATIONS', data)
                })
        }
    },
    getAutoapproveUsers({ commit }, filters = []) {
        return LoginService.getAutoapproveUsers(filters)
            .then(({ data }) => {
                commit('ADD_AUTO_APPROVE_USER_DATA', data)
            })
    },
    removeAutoapprove(address) {
        return LoginService.removeAutoapprove(address)
            .then(({ data }) => {
                return data;
            })
    },
    removeDefaultVideo(videoData) {
        let defaultVideo = {
            'videoId': videoData.videoId
        }

        return LoginService.removeDefaultVideo(defaultVideo)
            .then(({ data }) => {
                return data;
            })
    },
    async getRewardInfo({ commit, getters }, address) {
        const surveyAddress = process.env.VUE_APP_SURVEY_ADDRESS

        let result = await ahtTransactions.getRewardInfo({
            contractAddress: surveyAddress,
            surveyName: getters.getSurveyName,
            address: address
        })

        commit('ADD_REWARD_INFO', result);
    },
    async getSurveyName({ commit }) {
        let surveyName =  await ahtTransactions.getSurveyActive()

        commit('ADD_SURVEY_NAME', surveyName)
    },
    async sendRewards({ getters }, data) {
        const surveyAddress = process.env.VUE_APP_SURVEY_ADDRESS
        let web3 = web3v1Factory.getInstance()
        let participants = []
        let rewards = []


        data.usersToPay.forEach(user => {
            participants.push(user.address)

            let tokenAmount = web3.utils.toBN(user.originalReward)
            let reward = web3.utils.toHex(tokenAmount);

            rewards.push(reward)
        })

        let txData = {
            surveyName: getters.getSurveyName,
            contractAddress: surveyAddress,
            participantsAddress: participants,
            rewards: rewards,
            address: data.userAddress
        }
        
        await ahtTransactions.sendReward(txData)
    },
    async getSurveyContractBalance({ commit }) {
        const surveyAddress = process.env.VUE_APP_SURVEY_ADDRESS

        let balance = await ahtTransactions.getSurveyContractBalance({
            contractAddress: surveyAddress
        })

        commit('ADD_CONTRACT_BALANCE', balance);
    },
    async getSurveyList({ commit }) {
        let web3 = web3v1Factory.getInstance()
        const surveyAddress = process.env.VUE_APP_SURVEY_ADDRESS
        let surveyList = []

        let surveys = await ahtTransactions.getSurveyList({
            contractAddress: surveyAddress
        })

        for (let i = 0; i < surveys.length; i++) {
            let options = []
            let surveyData = await ahtTransactions.getSurvey({
                contractAddress: surveyAddress,
                surveyName: surveys[i]
            })

            for (let y = 0; y < surveyData.options.length; y++) {
                options.push(web3.utils.hexToAscii(surveyData.options[y]))
            }

            surveyList.push({
                title: web3.utils.hexToAscii(surveys[i]),
                rewardPool: web3.utils.fromWei(surveyData.rewardPool),
                creationDate: new Date(surveyData.creationDate * 1000),
                endDate: new Date(surveyData.endDate * 1000),
                options: options,
                surveyName: surveys[i]
            })
        }

        commit('ADD_CONTRACT_LIST', surveyList)
    },
    async setSurveyActive ({ dispatch }, data) {
        const surveyAddress = process.env.VUE_APP_SURVEY_ADDRESS

        let txData = {
            surveyName: data.surveyName,
            contractAddress: surveyAddress,
            address: data.address
        }

        await ahtTransactions.setSurveyAsActive(txData)

        dispatch('getSurveyName')
    },
    async getTransactionCount ({ commit }, address) {
        let count = await ahtTransactions.getTransactionCount(address);

        commit('ADD_TRANSACTION_COUNT', count);
    },
    async getIsEnrolled ({ commit }, address) {
        let eConsentAddress = process.env.VUE_APP_ECONSENT_ADDRESS
        let agreement = 'bhapp_share_data_for_research'

        let data = await ahtTransactions.isEnrolled({
            address: address,
            contractAddress: eConsentAddress,
            agreement: agreement
        })

        commit('SET_ENROLLED', data[0])
    },
    async getTransactionReceipt ({ commit }, txnHash) {
        let details = await ahtTransactions.getTransactionDetail(txnHash);

        let moreDetails = await ahtTransactions.getTransactionReceipt(txnHash);

        if (moreDetails !== null) {
            details.success = parseInt(moreDetails.status)
        } else {
            details.success = -1
        }

        commit('ADD_TRANSACTION_DETAILS', details)
    },
    async getAmountStacked ({ commit }) {
        const surveyAddress = process.env.VUE_APP_SURVEY_ADDRESS

        let amountStacked = await ahtTransactions.getAmountStacked({
            contractAddress: surveyAddress
        })

        commit('ADD_AMOUNT_STACKED', amountStacked)
    },
    getLiquidityEventsList ({ commit }, filter = '') {
        return LoginService.getLiquidityEvents(filter)
            .then(({data}) => {
                commit('ADD_LIQUIDITY_EVENTS_LIST', data)
            })
    },
    getLiquidityPoolList ({ commit }, filter = '') {
        return LoginService.getLiquidityPool(filter)
            .then(({data}) => {
                commit('ADD_LIQUIDITY_POOL_LIST', data)
            })
    },
    async addNewLiquidityEvent ({ dispatch }, event) {
        await LoginService.addNewLiquidityEvent(event)

        await dispatch('getLiquidityEventsList')
    },
    async removeLiquidityEvent ({ dispatch }, event) {
        await LoginService.removeLiquidityEvent(event)

        await dispatch('getLiquidityEventsList')
    },
    areDatesValid ({ commit }, dates) {
        return LoginService.validateDates(dates)
            .then(({data}) => {
                commit('UPDATE_DATE_STATUS', data)
            })
    },
    async getMultiTransferBalancer({ commit }) {
        let amount = await ahtTransactions.getContractBalance()

        commit('ADD_MULTI_TRANSFER_BALANCE', amount)
    },
    async isManager({ commit }, address) {
        let isManager = await ahtTransactions.isManager(address)

        commit('ADD_MANAGER_PERMISSION', isManager)
    },
    async sendLiquidityPoolReward ({ dispatch }, data) {
        await ahtTransactions.multiTransferReward(data)

        await LoginService.updatePaymentStatus(data)
            .then(async ({ data }) => {
                await dispatch('getLiquidityPoolList')
                await dispatch('getMultiTransferBalancer')
            })
    },
    areNameValid ({ commit }, event) {
        return LoginService.validateName(event)
            .then(({data}) => {
                commit('UPDATE_NAME_STATUS', data)
            })
    },
    async approveImage({ commit }, imageData) {
        const tokenAddress = process.env.VUE_APP_AHT_MATIC_ADDRESS
        
        try {
            if(imageData.rewardTx === '') {
                const txData = {
                    'contractAddress': tokenAddress,
                    'address': imageData.address,
                    'userAddress': imageData.userAddress,
                    'AHT': imageData.aht,
                };
            
                const txHash = await ahtTransactions.payReward(txData);

                imageData.rewardTx = txHash;
            }

            return LoginService.approveImage(imageData)
                .then(({data}) => {
                    commit('APPROVE_VIDEO', data)
                })

        } catch (error) {
            throw error;
        }
    },
    async rejectImage({ commit }, imageData) {
        try {
            return LoginService.rejectImage(imageData)
                .then(({data}) => {
                    commit('REJECT_VIDEO', data)
                })

        } catch (error) {
            throw error;
        }
    },
    getBPMList ({ commit }, filter = '') {
        return LoginService.getBPMVideos(filter)
            .then(({data}) => {
                commit('ADD_BPM_LIST', data);
            });
    },
    async approveBPMVideo({ commit }, videoData) {
        const tokenAddress = process.env.VUE_APP_AHT_MATIC_ADDRESS;

        try {
            if(videoData.rewardTx === '') {
                const txData = {
                    'contractAddress': tokenAddress,
                    'address': videoData.address,
                    'userAddress': videoData.userAddress,
                    'AHT': videoData.rewardAmount,
                };
            
                const txHash = await ahtTransactions.payReward(txData);

                videoData.rewardTx = txHash;
            }

            return LoginService.approveBPMVideo(videoData)
                .then(({data}) => {
                    commit('APPROVE_VIDEO', data)
                })

        } catch (error) {
            throw error;
        }
    },
    async rejectBPMVideo({ commit }, videoData) {
        try {
            return LoginService.rejectBPMVideo(videoData)
                .then(({data}) => {
                    commit('REJECT_VIDEO', data)
                })

        } catch (error) {
            throw error;
        }
    },
    async getBPMCountUser({ commit }, filter = '') {
        return LoginService.getBPMCount(filter)
            .then(({data}) => {
                commit('ADD_BPM_COUNT', data);
            });
    },
    async approveMultiBPMVideo({ commit }, data) {
        const web3 = web3v1Factory.getInstance()
        const tokenAddress = process.env.VUE_APP_MULTI_TRANSFER_BPM_ADDRESS;
        let addresses = [];
        let tokens = [];
        let videoId = [];
        let requestData = {};

        try {
            data.users.forEach(user => {
                videoId.push(user.videoId);
                addresses.push(user.paymentAddress);
                tokens.push(web3.utils.toWei(String(data.rewardAmount), 'ether'));
            });


            const txData = {
                'contractAddress': tokenAddress,
                'address': data.address,
                'addresses': addresses,
                'tokens': tokens
            };
        
            const txHash = await ahtTransactions.payMultiReward(txData);

            requestData.rewardTx = txHash;
            requestData.rewardAmount = data.rewardAmount;
            requestData.videosId = videoId;

            return LoginService.approveMultiBPMVideo(requestData)
                .then(({data}) => {
                    commit('APPROVE_VIDEO', data)
                })

        } catch (error) {
            throw error;
        }
    },
    async rejectMultiBPMVideo({ commit }, data) {
        try {
            return LoginService.rejectMultiBPMVideo(data)
                .then(({data}) => {
                    commit('REJECT_VIDEO', data)
                })
        } catch (error) {
            throw error;
        }
    },
    async isBPMManager({ commit }, address) {
        let isManager = await ahtTransactions.isBPMManager(address)

        commit('ADD_MANAGER_PERMISSION', isManager)
    },
}

export const getters = {
    getRewardInfo (state) {
        return state.rewardInfo
    },
    getSurveyName (state) {
        return state.surveyName
    },
    getContractBalance (state) {
        return state.contractBalance
    },
    getSurveys (state) {
        return state.surveyList
    },
    getTransactionCount (state) {
        return state.transactionCount
    },
    getTransactionDetails (state) {
        return state.transactionDetails
    },
    getIsEnrolled (state) {
        return state.enrolled
    },
    getAmountStacked (state) {
        return state.amountStacked
    },
    getLiquidityEvents (state) {
        return state.liquidityEvents
    },
    getLiquidityPool (state) {
        return state.liquidityPool
    },
    getDateStatus (state) {
        return state.validDate
    },
    getMultiTransferBalance (state) {
        return state.multiTransferBalance
    },
    getIsManager (state) {
        return state.manager
    },
    getNameStatus (state) {
        return state.validName
    },
    getUsers (state) {
        return state.users
    },
    getBPMPaidUsers (state) {
        return state.bpmPaidUsers
    },
    getBPMPendingUsers (state) {
        return state.bpmPendingUsers
    },
    getTotalPaidUsers (state) {
        return state.bpmCountPaidUsers
    },
    getTotalPendingUsers (state) {
        return state.bpmCountPendingUsers
    }
}
