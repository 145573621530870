import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import LoginService from '@/services/LoginService.js'
import moment from 'moment'

import * as stats from '@/store/modules/stats.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        stats
    },
    state: {
        hash: '',
        address: '',
        private: {},
        habits: [],
        addressWallet: '',
        wallet: 0,
        wallets: [
            'Metamask',
            'Trezor'
        ],
        isConnected: false,
    },
    mutations: {
        SET_USER_DATA(state, userData) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token
                }`
        },
        SET_USER_HASH(state, hash) {
            let object = { 'hash': hash, 'expires': moment().add('8', 'h').format('x'), }
            window.localStorage.setItem('admin-hash', JSON.stringify(object));

            if (hash === null) {
                window.localStorage.removeItem('credentials');
                window.localStorage.removeItem('admin-hash');
            }

            state.hash = hash
        },
        SET_PRIVATE_DATA(state, data) {
            state.private = data
        },
        SET_CREDENTIALS_DATA(state, credentials) {
            window.localStorage.setItem('credentials', JSON.stringify(credentials));
        },
        SET_HABIT_LIST (state, data) {
            state.habits = data.habits
        },
        ADD_IS_CONNECTED (state, status) {
            state.isConnected = status
        },
        UPDATE_WALLET (state, wallet) {
            state.wallet = wallet
        },
        ADD_ADDRESS (state, address) {
            state.addressWallet = address
        },
    },
    actions: {
        async login({ commit, dispatch, state }, credentials) {
            await dispatch('getHash', credentials.address)
            const serverHash = state.hash

            const hash = Buffer.from(serverHash, 'hex')
            const signature = ethutil.ecsign(hash, Buffer.from(credentials.privateKey, 'hex'))

            const signData = {
                hash: serverHash,
                sigR: signature.r.toString('hex'),
                sigS: signature.s.toString('hex'),
                sigV: signature.v,
            }

            commit('SET_PRIVATE_DATA', signData)
            commit('SET_CREDENTIALS_DATA', credentials)

            return LoginService.postLogin(signData)
                .then(({ data }) => {
                    commit('SET_USER_DATA', data)
                })
        },
        getHash({ commit }, address) {
            return LoginService.getHash(address)
                .then(({ data }) => {
                    commit('SET_USER_HASH', data.hash)
                })
        },
        logout({ commit }) {
            return LoginService.logout()
                .then(() => {
                    commit('SET_USER_HASH', null)
                })
        },
        getHabitList({ commit }) {
            return LoginService.getHabitList()
                .then(({ data }) => {
                    commit('SET_HABIT_LIST', data)
                })
        },
        async editHabit({ dispatch }, data) {
            return await LoginService.updateHabit(data).then(async function () {
                await dispatch('getHabitList')
            })
        },
        removeHabit({ dispatch }, id) {
            let data = {
                id: id,
            };

            return LoginService.removeHabit(data).then(async function () {
                await dispatch('getHabitList')
            });
        },
        approveHabit({ dispatch }, id) {
            let data = {
                id: id,
            }

            return LoginService.approveHabit(data).then(async function () {
                await dispatch('getHabitList')
            });
        },
        disapproveHabit({ dispatch }, id) {
            let data = {
                id: id,
            }

            return LoginService.disapproveHabit(data).then(async function () {
                await dispatch('getHabitList')
            });
        },
        setIsConnected ({commit}, status) {
            commit('ADD_IS_CONNECTED', status)
        },
        setWallet ({commit}, wallet) {
            commit('UPDATE_WALLET', wallet)
        },
        setAddress ({commit}, address) {
            commit('ADD_ADDRESS', address)
        },
    },
    getters: {
        getHash(state) {

            if (!state.hash) {
                let hashData = window.localStorage.getItem('admin-hash');
                if (hashData) {
                    let data = JSON.parse(hashData)

                    if (data.expires >= moment().format('x')) {
                        return data.hash
                    } else {
                        window.localStorage.removeItem('admin-hash')
                        window.localStorage.removeItem('credentials')
                    }
                }
            }

            return state.hash;
        },
        getHabitList (state) {
            return state.habits
        },
        getIsConnected (state) {
            return state.isConnected
        },
        getWallet (state) {
            return state.wallets[state.wallet]
        },
        getAddressMetamask (state) {
            return state.addressWallet
        },
    }
})