import axios from 'axios'
import NProgress from 'nprogress'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URI,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 50000
})

const apiDataClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URI,
    withCredentials: true,
    timeout: 50000
})

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})

apiDataClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})

apiClient.interceptors.response.use(response => {
    NProgress.done()
    return response
})

apiDataClient.interceptors.response.use(response => {
    NProgress.done()
    return response
})

export default {
    postLogin(credentials) {
        return apiClient.post('/login', credentials)
    },
    getHash(address) {
        return apiClient.get('/challenge/?address=' + address)
    },
    getImageList(filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }

        return apiClient.get('/image/list' + qs)
    },
    getCountImages(filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }

        return apiClient.get('/image/count' + qs)
    },
    getVideoList(filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }

        return apiClient.get('/video/list' + qs)
    },
    getCountVideos(filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }

        return apiClient.get('/video/count' + qs)
    },
    getDefaultVideoList(filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }

        return apiClient.get('/default-video/list' + qs)
    },
    getCountDefaultVideos(filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }

        return apiClient.get('/default-video/count' + qs)
    },
    approveVideo(videoData) {
        return apiClient.post('/video/approve', videoData)
    },
    rejectVideo(videoData) {
        return apiClient.post('/video/revoke', videoData)
    },
    addDefaultVideo(videoData) {
        return apiClient.post('/default-video/add', videoData)
    },
    async uploadDefaultVideo(videoData, file) {
        const chunkSize = 5 * 1024 * 1024;
        const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        const blockCount = Math.ceil(file.size / chunkSize);
        let multipartUpload = {
            Parts: []
          }

        for (let i = 1; i <= blockCount; i++) {
            const start = (i-1) * chunkSize;
            const end = Math.min(file.size, start + chunkSize);

            const form = new FormData();
            form.append('file', blobSlice.call(file, start, end));
            form.append('chunkNumber', i);
            form.append('identifier', videoData.videoId);
            form.append('fileName', file.name);

            let part = await apiDataClient.post('/default-video/upload', form);

            multipartUpload.Parts.push(part.data);
        }

        const data = {
            uploadFinish: true,
            fileName: file.name,
            identifier: videoData.videoId,
            multipartUpload: multipartUpload
          };

        let dt = await apiClient.post('default-video/upload', data)
        
        return dt
    },
    logout() {
        return apiClient.post('/logout')
    },
    getNotifications() {
        return apiClient.get('/video/notifications')
    },
    getAutoapproveUsers(filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }

        return apiClient.get('/metadata/autoApproveList' + qs)
    },
    removeAutoapprove(user) {
        return apiClient.post('/metadata/removeAutoApprove', user)
    },
    removeDefaultVideo(videoData) {
        return apiClient.post('/default-video/remove', videoData)
    },
    getNonce(address) {
        return apiClient.get('/getNonce/?address=' + address)
    },
    updateNonce(nonceData) {
        return apiClient.post('/updateNonce', nonceData)     
    },
    getHabitList() {
        return apiClient.get('/habits')
    },
    updateHabit(data) {
        let habit = {
            'id': data.id,
            'name': data.name,
            'icon': data.icon,
            'status': data.status.code,
        }

        return apiClient.post('/habits/edit', habit)
    },
    removeHabit(data) {
       return apiClient.post('/habits/remove', data)
    },
    approveHabit(data) {
        return apiClient.post('/habits/approve', data)
    },
    disapproveHabit(data) {
        return apiClient.post('/habits/disapprove', data)
    },
    getLiquidityEvents (filter) {
        let params = ''

        if (filter !== '') {
            params = '/?filter=' + filter
        }

        return apiClient.get('/liquidity-events' + params)
    },
    getLiquidityPool (filter) {
        let params = ''

        if (filter !== '') {
            params = '/?filter=' + filter
        }

        return apiClient.get('/liquidity-pool' + params)
    },
    addNewLiquidityEvent (event) {
        return apiClient.post('/liquidity-event/add', event)
    },
    updateLiquidityEvent (event) {
        return apiClient.post('/liquidity-event/edit', event)
    },
    removeLiquidityEvent (event) {
        return apiClient.post('/liquidity-event/remove', event)
    },
    validateDates (eventDates) {
        return apiClient.post('/liquidity-event/valid-dates', eventDates)
    },
    updatePaymentStatus (data) {
        return apiClient.post('/liquidity-pool/update/payment', data)
    },
    validateName (event) {
        return apiClient.post('/liquidity-event/valid-name', event)
    },
    getVideoById (id) {
        return apiClient.get('/video/?id=' + id)
    },
    approveImage(imageData) {
        return apiClient.post('/image/approve', imageData)
    },
    rejectImage(imageData) {
        return apiClient.post('/image/revoke', imageData)
    },
    getBPMVideos (filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }

        return apiClient.get(`/bpm/videos${qs}`);
    },
    approveBPMVideo(videoData) {
        return apiClient.post('/bpm/video/approve', videoData)
    },
    rejectBPMVideo(videoData) {
        return apiClient.post('/bpm/video/revoke', videoData)
    },
    getBPMCount(filters) {
        let qs = '';

        if (filters && filters.length) {
            qs = '?' + filters.join('&');
        }
        
        return apiClient.get(`/bpm/count${qs}`)
    },
    approveMultiBPMVideo(data) {
        return apiClient.post('/bpm/video/multi-approve', data)
    },
    rejectMultiBPMVideo(data) {
        return apiClient.post('/bpm/video/multi-revoke', data)
    },
}