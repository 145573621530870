import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import i18n from './i18n/i18n'
import store from './store/store'
import DateRangePicker from "@gravitano/vue-date-range-picker";
import { BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import VueSimpleAlert from "vue-simple-alert";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false

Vue.use(DateRangePicker);

Vue.use(ArgonDashboard)

Vue.use(VueSimpleAlert);

import 'bootstrap-vue/dist/bootstrap-vue.css'

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')